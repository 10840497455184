import React, { useMemo } from 'react';
import styles from './index.module.less';
import { mainLabelMap, getRouteTitleMap, getPageTitleMap } from '@/common/mainLabel';

const TopContent = ({ data, pageContent }: { data?: any; pageContent?: any }) => {
  const h1Title = useMemo(() => {
    return getRouteTitleMap(data?.pathId, data?.label, data?.gender);
  }, [data]);
  const getTitle = useMemo(() => {
    return getPageTitleMap(data?.pathId, data?.label, data?.gender);
  }, [data]);

  return (
    <div className={styles.content}>
      <h1 style={{ display: 'none', width: 0, height: 0 }}>{h1Title}</h1>

      <div className={styles.title}>
        {mainLabelMap.get(data?.pathId) == 'Hair Types' && (
          <>
            <span style={{ color: '#FFFFFF' }}>Best </span>
            <span>Hairstyles</span>
            <span style={{ color: '#FFFFFF' }}>For {getTitle}</span>
          </>
        )}
        {mainLabelMap.get(data?.pathId) == 'Colors' && (
          <>
            <span style={{ color: '#FFFFFF' }}>{getTitle}</span>
            <span style={{ color: '#FFFFFF' }}>Virtual </span>
            <span>Hair Color</span>
            <span style={{ color: '#FFFFFF' }}>Try-On</span>
          </>
        )}

        {mainLabelMap.get(data?.pathId) == 'Trending' && (
          <>
            <span style={{ color: '#FFFFFF' }}>{getTitle}</span>
            <span style={{ color: '#B57CCE' }}>Trending</span>
            <span style={{ color: '#FFFFFF' }}>Hairstyles in 2025</span>
          </>
        )}
        {mainLabelMap.get(data?.pathId) == 'Face Shapes' && (
          <>
            <span style={{ color: '#FFFFFF' }}>The Best Hairstyles for</span>
            <span style={{ color: '#53BD70' }}>{getTitle}</span>
          </>
        )}
      </div>

      <p className={styles.desc}>{pageContent?.contentInfo?.['section-title']?.desc}</p>
    </div>
  );
};

export default TopContent;
